body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui;
}
a{
    text-decoration: none;
    color: inherit;
}

