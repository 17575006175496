@import "../../styles/variables.scss";

.contacts {
  ul {
    list-style-type: none;
    color: white;
    li {
      padding: 7px;
      h3 {
        font-size: $title-size;
        font-weight: 800;
        margin: 13px 0;
        a {
          cursor: pointer;
        }
      }
      p {
        font-size: $paragraph-size;
        margin: 8px 0;
      }
    }
  }
  .hover{
          &:hover{
            color: $primary-color;
          }
        }
}
