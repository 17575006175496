@import "../../styles/variables";
@import "../../styles/mediaMixins";
.according {
  .header {
    font-size: 1.25rem;
    font-weight: 600;
    padding: 15px 30px;
    border-bottom: 1px solid $dark-primary-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: rgb(248, 209, 204);
    }
    @include mediaSm {
      font-size: 1.1rem;
      padding: 20px 5px;
    }
  }
  .title {
    letter-spacing: 0px;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: none;
  }
  .icon {
    height: 20px;
    width: 20px;
    color: $dark-primary-color;
    margin-left: auto;
  }
  .text {
    padding: 20px;
    font-size: 17px;
    text-align: justify;
    line-height: 1.4;
    @include mediaSm {
      font-size: 15px;
    }
  }
}
