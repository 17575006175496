@import "../../styles/variables.scss";
@import "../../styles/mediaMixins.scss";

.contactPage {
  background-color: $header-bgColor;
  padding: 100px 0;
  position: relative;
  width: 100%;
  @include mediaSm {
    padding: 20px 0;
  }
  .container {
    padding: 30px 100px;
    background-color: $secondary-color;
    @include mediaMd {
      padding: 0;
    }
  }
}

.form-container {
  position: absolute;
  right: 0;
  top: 50px;
  margin-right: 100px;
  width: 500px;
  box-shadow: 5px 5px 20px 0 #999999;
  color: #111111;
  background-color: #ffffff;
  border: 1px solid #999999;
  padding: 30px 50px 30px 40px;
  @include mediaLg {
    margin-right: 30px;
  }
  @include mediaMd {
    margin-right: 20px;
    width: 300px;
  }
  @include mediaSm {
    top: 500px;
    width: 300px;
  }
  @include mediaXSm {
    width: 240px;
  padding: 20px;

  }
  h2 {
    margin-left: 20px;
    font-size: 35px;
    font-family: Montserrat, sans-serif;
    @include mediaSm {
      font-size: 25px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    input[type=text],
    input[type=tel],
    input[type=email],
    textarea {
      padding: 15px;
      font-size: 18px;
      border: 0;
      border-bottom: 1px solid black;
      margin: 20px;
      @include mediaSm {
        padding: 5px;
      }
      @include mediaXSm {
        font-size: 15px;
      }
    }
    input[type=submit] {
      margin-left: 20px;
      min-width: 100px;
      height: 50px;
      border: 1px solid black;
      color: black;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: black;
      }
      @include mediaSm {
        height: 35px;
      }
    }
  }
}
