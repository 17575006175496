@import "../../styles/variables.scss";
@import "../../styles/mediaMixins.scss";

.footer {
  display: flex;
  justify-content: space-evenly;
  background-color: $secondary-color;
  @include mediaSm {
    flex-direction: column;
    align-items: center;
  }
  .map-container {
    margin: auto 0;
    width: 400px;
    height: 400px;
    @include mediaLg {
      width: 350px;
      height: 350px;
    }
    @include mediaMd {
      width: 300px;
      height: 300px;
    }

    .map {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
