@import "../../styles/variables.scss";
@import "../../styles/mediaMixins.scss";
@import "../../styles/mixins.scss";

.page-header {
  width: 100%;
  background-color: $header-bgColor;
  padding: 50px;
  box-sizing: border-box;
  @include mediaSm {
    padding: 15px;
  }
  .companyName {
    font-size: 60px;
    width: fit-content;
    margin-left: 80px;
    line-height: 0.87;
    font-family: system-ui;
    @include mediaSm {
      font-size: 43px;
      margin: 0 auto;
    }
    .row {
      display: flex;
      justify-content: flex-end;
      .by {
        font-size: 35px;
        margin-right: 10px;
        @include mediaSm {
          font-size: 25px;
        }
      }
    }
  }

  .imgContainer {
    position: relative;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    margin: 30px auto;
    @include mediaSm {
      width: 355px;
      height: 355px;
    }
    @include mediaSm {
      width: 280px;
      height: 280px;
    }
    img {
      width: inherit;
      height: inherit;
      border-radius: 50%;
      z-index: 0;
    }
    .circle {
      position: absolute;
      margin-left: 30px;
      background-color: white;
      border-radius: 50%;
      width: 138px;
      height: 138px;
      box-shadow: $box-shadow;
      z-index: 1;
      @include mediaSm {
        width: 90px;
        height: 90px;
      }
      @include mediaXSm {
        width: 70px;
        height: 70px;
      }
    }
  }
  .header_status {
    display: flex;
    align-items: center;
    margin-left: 50px;
    @include mediaSm {
      margin: 0;
      padding: 10px;
    }
    .borderedCircle {
      @include borderedCircle();
      @include mediaMd {
        width: 130px;
        height: 90px;
        border-width: 25px;
      }
      @include mediaSm {
        display: none;
      }
    }
    .status {
      font-size: 70px;
      color: $primary-color;
      text-align: center;
      margin-left: 150px;
      font-style: italic;
      @include mediaMd {
        margin-left: 60px;
        font-size: 60px;
        font-weight: bold;
      }
      @include mediaSm {
        font-size: 44px;
        margin: 0 auto;
      }
    }
  }
}
