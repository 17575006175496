@import "../../styles/mediaMixins";
@import "../../styles/variables";
@import "../../styles/mixins";
%listStyleNone {
  list-style-type: none;
}

.sectionServices {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @include mediaMd {
    flex-direction: column;
  }
  .imgContainer {
    @include imgSection;
    position: relative;
    img {
      width: inherit;
      height: inherit;
      border-radius: 50%;
    }
  }
  .text {
    width: 50%;
    @include mediaMd {
      width: 100%;
      margin-top: 20px;
    }
    h2 {
      @include titleSection;
    }
    ul {
      @extend %listStyleNone;
      display: grid;
      grid-template-columns: auto auto;
      position: relative;
      min-height: 150px;
      li {
        font-size: 20px;
        line-height: 1.5;
        &::before {
          @include circleBefore;
        }
        @include mediaSm {
          font-size: 18px;
        }
      }
    }
    .text-footer {
      display: flex;
      justify-content: space-evenly;
      .learnMoreBtn {
        @include learnMoreBtn;
      }
      .bookAppointment {
        @include bookAppointmentBtn;
      }
    }
  }
}
