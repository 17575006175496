@import "../../styles/mediaMixins";
@import "../../styles/variables";
@import "../../styles/mixins";

%listStyleNone {
  list-style-type: none;
}

.chooseElectrolysis {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include mediaMd {
    flex-direction: column-reverse;
    margin: 40px 0;
  }

  .text {
    width: 60%;
    @include mediaMd {
      width: 100%;
      margin-top: 20px;
    }
    h2 {
      @include titleSection;
    }
    ul {
      @extend %listStyleNone;

      li {
        font-size: 20px;
        line-height: 1.5;
        @include mediaSm {
          font-size: 18px;
          line-height: 2;
        }
        &::before {
          @include circleBefore;
        }
      }
    }
    .text-footer {
      display: flex;
      justify-content: center;
      .learnMoreBtn {
        @include learnMoreBtn;
      }
    }
  }
  .imgContainer {
    @include imgSection;
    position: relative;
    img {
      width: inherit;
      height: inherit;
      border-radius: 50%;
    }
  }
}
