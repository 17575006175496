@import "../../styles/variables";
@import "../../styles/mediaMixins";

.faq {
  width: 85%;
  min-height: 1004px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .img {
    width: 60%;
    height: 380px;
    margin: 60px auto 0;
    @include mediaSm {
      height: 200px;
      margin: 20px auto 0;
    }
    @include mediaXSm {
      height: 160px;
    }
  }
  .according-container {
    width: 80%;
    margin: 20px auto 60px;
    @include mediaSm {
     width: 95%;
    }
  }
}
