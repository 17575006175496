@import "./variables.scss";
@import "./mediaMixins.scss";

@mixin circleBefore {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  background-color: $secondary-color;
}

@mixin imgSection {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

@mixin titleSection {
  color: $primary-color;
  text-align: center;
  font-size: 30px;
  @include mediaSm {
    font-size: 26px;
  }
}

@mixin learnMoreBtn {
  background-color: $primary-color;
  color: white;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: $dark-primary-color;
  }
}

@mixin bookAppointmentBtn {
  background-color: $secondary-color;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #4b727e;
  }
}

@mixin borderedCircle($size: 118px, $border: 30px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: $border solid $primary-color;
}
