@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/mediaMixins";

.sectionMethodsV2 {
  width: 90%;
  height: fit-content;
  margin: 100px auto;
  // background-color: $header-bgColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  margin: 40px auto;
  @include mediaMd {
    width: 80%;
    margin: 60px auto;
  }
  @include mediaSm {
    border-radius: 10%;
    width: 90%;
    margin: 40px 0;
  }
  p {
    font-size: larger;
  }
  h2 {
    @include titleSection;
    margin-left: 20px;
  }
  footer {
    margin-left: 16px;
    text-align: center;
    font-style: italic;
    font-size: 27px;
    // color: $secondary-color;
    color: #1c8eb1;
    font-weight: 700;
    @include mediaSm {
     font-size: 20px;
    }
  }

  ul {
    list-style-type: none;
    li {
      font-size: 20px;
      line-height: 1.5;
      // text-align: justify;
      // b{
      //   font-size: 20px;
      //   @include mediaSm {
      //     font-size: 18px;
      //   }
      // }
      @include mediaSm {
        font-size: 18px;
        line-height: 2;
      }
      &::before {
        @include circleBefore;
      }
    }
  }
}
