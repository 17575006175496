@import "../../styles/variables.scss";
@import "../../styles/mediaMixins.scss";

.active {
  color: $secondary-color;
}

nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include mediaMd {
    justify-content: center;
  }

  ul {
    list-style: none;
    margin-right: 100px;
    @include mediaMd {
      margin-right: 0;
    }
    @include mediaXSm{
      padding: 0;
    }
    li {
      display: inline;
      font-size: 20px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
      @include mediaSm {
        padding: 5px;
        font-size: 17px;
      }
      @include mediaXSm {
        font-size: 14px;
      }
    }
  }
}
