@import "../../styles/variables.scss";
@import "../../styles/mediaMixins.scss";

.aboutMe {
  width: 60%;
  height: fit-content;
  margin: 100px auto;
  background-color: $header-bgColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  @include mediaMd {
    width: 80%;
    margin: 60px auto;
  }
  @include mediaSm {
    border-radius: 10%;
    width: 90%;
    margin: 40px auto;
  }
  h1 {
    color: $primary-color;
    font-size: 30px;
    letter-spacing: 10px;
  }
  .imgContainer {
    position: relative;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    @include mediaMd {
      width: 160px;
      height: 160px;
    }
    @include mediaSm {
      width: 140px;
      height: 140px;
    }
    img{
      width: inherit;
      height: inherit;
      border-radius: 50%;
    }
  }
  .text {
    font-style: italic;
    margin-top: 20px;
    width: 40%;
    text-align: justify;
    line-height: 2;
    font-size: 19px;
    @include mediaMd {
      width: 60%;
    }
    @include mediaSm {
      width: 90%;
      font-size: 17px;
    }
  }
}
