@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/mediaMixins";

.servicePageV2 {
  background-color: $header-bgColor;
  padding: 50px;
  @include mediaSm {
    padding: 35px 25px;
  }
  .header {
    display: flex;
    justify-content: space-evenly;
    @include mediaSm {
      flex-direction: column;
      align-items: center;
    }
    h1 {
      font-size: 30px;
      color: $primary-color;
      font-weight: bold;
      letter-spacing: 2px;
    }
    button {
      @include bookAppointmentBtn;
    }
  }
  .container {
    padding: 40px;
    @include mediaSm {
      padding: 20px 0;
    }
    .row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .borderedCircle {
        @include borderedCircle($size: 100px, $border: 20px);
        font-size: 25px;
        color: $secondary-color;
        font-style: italic;
        font-weight: 500;
        margin: 20px 0;
        box-shadow: $box-shadow;
        @include mediaSm {
          @include borderedCircle($size: 80px, $border: 14px);
          font-size: 20px;
        }
        .min-fee{
          font-size: 23px;
          @include mediaSm {
            font-size: 17px;
          }
        }
        .time {
          font-size: 41px;
          @include mediaSm {
            font-size: 35px;
          }
        }
      }
      .price {
        font-size: 60px;
        color: $secondary-color;
        font-style: italic;
        @include mediaSm {
          font-size: 45px;
        }
      }
    }
    hr {
      background-color: $primary-color;
      height: 2px;
    }
.bikini-text{
  font-size: 35px;
    color: $secondary-color;
    font-style: italic;
    font-weight: 500;
    margin: 20px 0;
    @include mediaSm {
      font-size: 27px;
    }
    @include mediaXSm {
      font-size: 21px;
    }
}

.bikini-price{
       font-size: 55px;
        color: $secondary-color;
        font-style: italic;
        @include mediaSm {
          font-size: 33px;
        }
        @include mediaXSm {
          font-size: 25px;
        }
}
  
  }
  .bikini-row{
    margin-left: 55px;
    padding: 20px 0;
    @include mediaSm {
      margin-left: 20px;
    }
    @include mediaXSm {
      padding: 4px;
      margin-left: 4px;
    }
  }
}
